import {
  Link
} from 'react-router-dom';

import React from 'react';

import '../styles/style.css'
import {
  connect
} from 'react-redux';
import App from '../App.js'
import logout from '../img/logout.svg';
import logo from '../img/mawi_logotype_full_w.svg';
import DatasetsList from '../components/Lists/DatasetsList'

class Datasets extends App {


  constructor(props) {
    super(props);
    this.state = {
        datasets: null,
        user: JSON.parse(localStorage.getItem('user'))
      }
  }

  render() {

    return (
      <div className="App">
        <div className="App-header">
          <div className="App-header-content">
            <div className="App-header-left">
              <Link to="/">
                <img src={logo} className="App-logo" alt="logo" />
              </Link>
            </div>
            <div className="App-header-right">
              <div className="App-header-right-seperator"/>
              <div className="App-header-logout-div">
                <Link className="logout-Link" to="/login" onClick={this.handleLogout}>
                  <img src={logout} className="App-header-logout-img" alt="logout" />
                  <p className="App-header-logout-txt">Logout</p>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="Datasets">
          <DatasetsList/>
        </div>
      </div>

    )
  }
};

function mapStateToProps(state) {
  return {
      //datasets: state.datasets
  }
}

function mapDispatchToProps(dispatch) {
  return {
    //storeDoctorAction: bindActionCreators(storeDoctorAction, dispatch),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Datasets)
//export default Datasets
