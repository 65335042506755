// real indexes
function annToInd(values, offset, currentIndex) {
  return values.map( x => x + offset * currentIndex);
}

// for chart points indexing
function indToAnn(values, offset, currentIndex) {
  return values.map(x => x - offset * currentIndex);
}

// ann_new_x[i] = json.manual_annotation.filter(
//   x => i * offset < x < (i + 1) * offset
// );

function filterRange(values, offset, index) {
  return values.filter(x => index * offset <= x && x < (index + 1) * offset);
}

export { annToInd, indToAnn, filterRange };
