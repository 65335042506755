import { SET_ID } from '../constants/Page';

const initialState = {
  ids: ''
}

export default function usersID(state = initialState, action) {
  switch (action.type) {
    case SET_ID:
      return { ...state, ids: action.payload }

    default:
      return state;
  }
}

