import { STORE_DOCTOR_INFO } from '../constants/Page';

const initialState = {
  doctorInfo: null
}

export default function doctorInfo(state = initialState, action) {
  switch (action.type) {
    case STORE_DOCTOR_INFO:
      return { ...state, doctorInfo: action.payload }

    default:
      return state;
  }
}
