import axios from "axios";
import dateFormat from "dateformat";

let RequestedDomain = "https://annotation.mawihealth.com/api/";
//let RequestedDomain = "http://localhost:8000/api/";

const APIrequest = {
  // utils
  auth_header: function(user) {
    return {
      Authorization: "Bearer " + user.access
    };
  },

  format_filters: function(filters) {
    let response = {};
    let compare_keys = [
      "frequency",
      "record_type",
      "is_blocked",
      "is_annotated",
      "annotated_by"
    ];

    let query_params = {
      duration_s_from: "ecg_duration__gte",
      duration_s_to: "ecg_duration__lte",
      duration_p_from: "ecg_length__gte",
      duration_p_to: "ecg_length__lte",
      from_date: "date_creation__gte",
      to_date: "date_creation__lte"
    };

    for (var key in filters) {
      if (compare_keys.includes(key)) {
        if (filters[key] != 0 || filters[key] != "") {
          if (key == "annotated_by") {
            response["current_contributor__username"] = filters[key];
            continue;
          }
          response[key] = filters[key];
          continue;
        }
      }

      if (key in query_params) {
        if (filters[key] != 0 && filters[key] != "" && filters[key] != null) {
          if (key != "from_date" && key != "to_date") {
            response[query_params[key]] = filters[key];
            continue;
          } else {
            let date = filters[key];
            response[query_params[key]] = dateFormat(date, "yyyy-mm-dd");
            continue;
          }
        }
      }
    }
    return response;
  },

  // api calls

  login: async function(data) {
    return axios({
      method: "post",
      url: RequestedDomain + "token/",
      data: data
    });
  },

  get_datasets_list: async function(user) {
    console.log(user);
    return axios({
      method: "get",
      url: RequestedDomain + "datasets/list/",
      headers: APIrequest.auth_header(user)
    });
  },

  get_records_list: async function(user, idDataset, pageNumber, filters) {
    let filtersDict = APIrequest.format_filters(filters);
    let pageStr = pageNumber.toString();
    return axios({
      method: "post",
      url: RequestedDomain + "datasets/" + idDataset + "/" + pageStr + "/",
      headers: APIrequest.auth_header(user),
      data: filtersDict
    });
  },

  get_points: async function(user, idRecord, batchNumber, offset,isInclAnn){
    return axios({
      method: "post",
      url: RequestedDomain + "get_record_data/",
      headers: APIrequest.auth_header(user),
      data: {
        "id_record" : idRecord,
        "page" : batchNumber,
        "offset" : offset,
        "is_include_annotation" : isInclAnn
      }
    });
  },

  save_annotation: async function(user, idRecord, ann, currPage) {
    return axios({
      method: "post",
      url: RequestedDomain + "post_annotation/",
      headers: APIrequest.auth_header(user),
      data: {
        "id_record" : idRecord,
        "real_annotation" : ann,
        "current_batch_number":currPage
      }
    });
  }
};

export default APIrequest;
