import React from 'react';
import APIrequest from '../APICalls/api';
import TextField from 'material-ui/TextField';
import FlatButton from 'material-ui/FlatButton';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import MySnackbarContentWrapper from './MySnackbarContent/MySnackbarContent';
import Snackbar from '@material-ui/core/Snackbar';
import '../styles/style.css'
// import logo from '../img/loginLogo.svg';

class Login extends React.Component {

  constructor(props) {
    super(props);
    this.loginUser = this.loginUser.bind(this);
    this.loginHandler = this.loginHandler.bind(this);
    this.checkLoggedInUsers = this.checkLoggedInUsers.bind(this);
    this.handleCloseSnackbar = this.handleCloseSnackbar.bind(this);
    this.checkLoggedInUsers();

    this.state = {
      passwordValue: '',
      emailValue: '',
      nameValue: '',
      register: false,
      dashboardTypeValue: 'security',
      userTypeValue: 'admin',
      SnackbarIsOpen: false,
    }
  }
  textfield = null;

  checkLoggedInUsers() {
    if (localStorage.getItem('user') !== null && localStorage.getItem('user') !== 'null') {
      this.props.history.push('/datasets');
    } else this.props.history.push('/')
  }

  handleChangePassword = (event) => {
    this.setState({
      passwordValue: event.target.value
    });
  };

  handleChangeEmail = (event) => {
    this.setState({
      emailValue: event.target.value
    });
  };

  handleChangeName = (event) => {
    this.setState({
      nameValue: event.target.value
    });
  };

  handleChangeInvite = (event) => {
    this.setState({
      inviteValue: event.target.value
    });
  };

  handleDashboardTypeValueChange = (event, index, value) => this.setState({
    dashboardTypeValue: value
  });
  handleUserTypeValueChange = (event, index, value) => this.setState({
    userTypeValue: value
  });

  registerHandler() {
    this.setState({
      register: true
    });
  }

  loginHandler() {
    this.setState({
      register: false
    });
  }

  async loginUser() {
    let data = {
      "username": this.state.emailValue,
      "password": this.state.passwordValue
    }

    APIrequest.login(data).then(response => {
        if (response.status === 200) {
          console.log(response)
          localStorage.setItem('user', JSON.stringify(response.data));
          this.checkLoggedInUsers();
        }
      })
      .catch((error) => {
        console.log(error);
        alert("Error");
        // this.setState({
        //   SnackbarIsOpen: true
        // });
      });
  }

  handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      SnackbarIsOpen: false
    });
  }

  render() {

    return (
      <div>
        <div className="App-Header app-login">
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={this.state.SnackbarIsOpen}
            autoHideDuration={5000}
            onClose={this.handleCloseSnackbar}
            message={<span id="message-id">Invalid email or password. Please check and try again.</span>}
          >
            <MySnackbarContentWrapper
              onClose={this.handleCloseSnackbar}
              variant="error"
              message={<span id="message-id">Invalid email or password. Please check and try again.</span>}
            />
          </Snackbar>

          <div className="login-content">
            {this.state.register ?
              <div className="login-form register-form">
                <span className="login-form-header">Register</span>
                <div className="login-fields-div">
                  <TextField
                    hintText="Username"
                    floatingLabelText="Username"
                    fullWidth={true}
                    onChange={this.handleChangeEmail}
                    id="loginTextFields-email"
                  />

                  <div className="register-fields-div">

                    <TextField
                      hintText="Enter your name"
                      floatingLabelText="Name"
                      fullWidth={true}
                      onChange={this.handleChangeName}
                      id="loginTextFields-name"
                    />

                    <TextField
                      hintText="Enter your password"
                      floatingLabelText="Password"
                      type="password"
                      onChange={this.handleChangePassword}
                      id="loginTextFields-password-2"
                      className="registerTextFields-password"
                      floatingLabelFixed={true}
                    />

                    <TextField
                      hintText="Renter your password"
                      floatingLabelText="Password"
                      type="password"
                      onChange={this.handleChangePassword2}
                      id="loginTextFields-password-3"
                      className="registerTextFields-password registerTextFields-repassword"
                      floatingLabelFixed={true}
                    />

                    <SelectField
                      floatingLabelText="Dashboard Type"
                      value={this.state.dashboardTypeValue}
                      onChange={this.handleDashboardTypeValueChange}
                      className="slect-feild"
                    >
                      <MenuItem value={"security"} primaryText="Security" />
                      <MenuItem disabled={true} value={"hr"} primaryText="HR" />
                      <MenuItem disabled={true} value={"doctors"} primaryText="Doctors" />
                      <MenuItem value={"common"} primaryText="Common" />
                    </SelectField>

                    <SelectField
                      floatingLabelText="User Type"
                      value={this.state.userTypeValue}
                      onChange={this.handleUserTypeValueChange}
                      className="slect-feild slect-feild-user-type"
                    >
                      <MenuItem value={'admin'} primaryText="Admin" />
                      <MenuItem value={'manager'} primaryText="Manager" />
                    </SelectField>

                    <TextField
                      hintText="Paste your invite here"
                      floatingLabelText="Invite"
                      fullWidth={true}
                      onChange={this.handleChangeInvite}
                      id="loginTextFields-invite"
                    />
                  </div>
                </div>
                <FlatButton className="login-form-submit-btn" label="Submit" fullWidth={true} onClick={this.registerUser}/>
                <div className="login-form-register-btn-div">
                  <span className="login-form-register-btn-text"> Already have an account?
                    <a className="login-form-register-btn" onClick={this.loginHandler}>Login</a>
                  </span>
                </div>
              </div>

            :

              <div className="login-form">
                <span className="login-form-header">Login</span>
                <div className="login-fields-div">
                  <TextField
                    hintText="Username"
                    floatingLabelText="Username"
                    fullWidth={true}
                    onChange={this.handleChangeEmail}
                    underlineFocusStyle={{ borderColor: '#38699e'}}
                    floatingLabelStyle={{ color: '#b0bec3' }}
                    floatingLabelFocusStyle={{ color: '#38699e' }}
                    floatingLabelFixed={true}
                    id="loginTextFields-email"
                    onKeyPress={(ev) => {
                      if (ev.key === 'Enter') {
                        this.loginUser();
                      }
                    }}
                  />
                  <TextField
                    hintText="Enter your password"
                    floatingLabelText="Password"
                    type="password"
                    fullWidth={true}
                    onChange={this.handleChangePassword}
                    floatingLabelStyle={{ color: '#b0bec3' }}
                    floatingLabelFocusStyle={{ color: '#38699e' }}
                    underlineFocusStyle={{ borderColor: '#38699e' }}
                    floatingLabelFixed={true}
                    id="loginTextFields-password"
                    onKeyPress={(ev) => {
                      if (ev.key === 'Enter') {
                        this.loginUser();
                      }
                    }}
                  />
                </div>
                <FlatButton className="login-form-submit-btn" label="Submit" fullWidth={true} onClick={this.loginUser}/>
              </div>

            }
          </div>

        </div>
      </div>
    )
  }
};

export default Login
