import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom'

import Login from './components/Login';
import Datasets from './components/Datasets';
import DatasetDetails from './components/DatasetDetails/DatasetDetails';
import Record from './components/Record/Record';
const Root = (props) => (

  <Router {...props}>
		<div>
	 		<Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/" component={Login} />
        <Route exact path="/datasets" component={Datasets} />
        <Route path={'/datasets/:id'} component={DatasetDetails}/>
        <Route path={'/record/:id'} component={Record}/>
			</Switch>

		</div>
	</Router>
);

export default Root;
