import { RANGE_SLIDER_EVENT } from '../constants/Page';

const initialState = {
  rsEvent: false
}

export default function rsEvent(state = initialState, action) {
  switch (action.type) {
    case RANGE_SLIDER_EVENT:
      return { ...state, rsEvent: action.payload }

    default:
      return state;
  }
}
