import { List, ListItem } from "material-ui/List";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import logo from "../../img/mawi_logotype_full_w.svg";
import logout from "../../img/logout.svg";
import Plot from "react-plotly.js";
import * as consts from "./Constants";
import Button from "@material-ui/core/Button";
import "./css/chart.css";

class ECGChart extends React.Component {
  constructor(props) {
    super(props);
    let annotation_x = [];
    let annotation_y = [];
    let ecg = [];

    let ecg_data = consts.ecg_line;
    ecg_data.y = ecg;

    let ann_def = consts.annotation_default_points;
    ann_def.x = annotation_x;
    ann_def.y = annotation_y;

    let layout = consts.layout_config;
    layout.height = 0.6 * this.props.height;
    layout.width = 0.9 * this.props.width;

    this.state = {
      ecg_data: ecg_data,
      ann_new: consts.annotation_new_points,
      hovered: consts.hovered_points,
      ann_def: ann_def,
      layout: layout,
      config: consts.chart_config,
      revision: 0
    };

    this.updateRevision = this.updateRevision.bind(this);
    this.setPoints = this.setPoints.bind(this);
  }

  updateRevision() {
    const { ecg_data, layout } = this.state;
    this.setState({ revision: this.state.revision + 1 });
    layout.datarevision = this.state.revision + 1;
  }

  setPoints(new_ecg_points, new_def_ann_x, new_new_ann_x) {
    const { ecg_data, ann_new, ann_def, layout } = this.state;
    ecg_data.y = new_ecg_points;

    let chart_new_x = null;
    let chart_def_x = null;
    if (new_new_ann_x.length == 0){
      chart_new_x = [];
      chart_def_x = new_def_ann_x;
    } else {
      console.log(new_def_ann_x);
      console.log(new_new_ann_x);

      chart_new_x = new_new_ann_x.filter(x => !new_def_ann_x.includes(x));
      chart_def_x = new_new_ann_x.filter(x => new_def_ann_x.includes(x));
      console.log(chart_new_x);
      console.log(chart_def_x);


    }
    ann_new.x = chart_new_x;
    ann_new.y = chart_new_x.map(x => new_ecg_points[x]);
    ann_def.x = chart_def_x;
    ann_def.y = chart_def_x.map(x => new_ecg_points[x]);

    let space = 20;
    layout.yaxis.range = [
      Math.min.apply(null, new_ecg_points) - space,
      Math.max.apply(null, new_ecg_points) + space
    ];
    layout.xaxis.range = [-space, new_ecg_points.length + space];

    this.updateRevision();
  }

  onClick = data => {
    const { ecg_data, ann_def, ann_new, layout, hovered } = this.state;

    let arrayRemove = function(arr, indexToRemove) {
      return arr.filter(function(value, index, arr) {
        return index != indexToRemove;
      });
    };

    let point_x = data.points[0].x;
    let point_y = data.points[0].y;
    if (ann_def.x.includes(point_x) && ann_def.y.includes(point_y)) {
      let indexToRemove = ann_def.x.indexOf(point_x);
      ann_def.x = arrayRemove(ann_def.x, indexToRemove);
      ann_def.y = arrayRemove(ann_def.y, indexToRemove);
    } else if (ann_new.x.includes(point_x) && ann_new.y.includes(point_y)) {
      let indexToRemove = ann_new.x.indexOf(point_x);
      ann_new.x = arrayRemove(ann_new.x, indexToRemove);
      ann_new.y = arrayRemove(ann_new.y, indexToRemove);
    } else {
      ann_new.x.push(point_x);
      ann_new.y.push(point_y);
      let indexToRemoveDef = ann_def.x.findIndex(
        x => point_x + 10 > x && point_x - 10 < x
      );

      if (indexToRemoveDef != -1) {
        ann_def.x = arrayRemove(ann_def.x, indexToRemoveDef);
        ann_def.y = arrayRemove(ann_def.y, indexToRemoveDef);
      }
    }

    this.updateRevision();
  };

  onHover = data => {
    const { hovered, layout } = this.state;

    let point_x = data.points[data.points.length - 1].x;
    let point_y = data.points[data.points.length - 1].y;

    hovered.x.push(point_x);
    hovered.y.push(point_y);

    this.updateRevision();
  };

  onUnhover = data => {
    const { hovered, layout } = this.state;

    hovered.x.pop();
    hovered.y.pop();
    this.updateRevision();
  };

  render() {
    return (
      <div className="content">
        <Plot
          data={[
            this.state.ecg_data,
            this.state.ann_def,
            this.state.ann_new,
            this.state.hovered
          ]}
          layout={this.state.layout}
          config={this.state.config}
          onClick={this.onClick}
          revision={this.state.revision}
          onHover={this.onHover}
          onUnhover={this.onUnhover}
        />
      </div>
    );
  }
}

export default ECGChart;
