import React from "react";
import "./css/recordslist.css";
import { List, ListItem } from "material-ui/List";
import Divider from "material-ui/Divider";
import APIrequest from "../../APICalls/api";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import Moment from "moment";
import ReactHtmlParser from "react-html-parser";
import BottomScrollListener from "react-bottom-scroll-listener";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import DateFnsUtils from "@date-io/date-fns";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker
} from "@material-ui/pickers";

class RecordsList extends React.Component {
  constructor(props) {
    super(props);

    let default_filters = {
      frequency: 0,
      record_type: "",
      annotated_by: "",
      is_blocked: false,
      is_annotated: false,
      from_date: null,
      to_date: null,
      duration_s_from: 0,
      duration_s_to: 0,
      duration_p_from: 0,
      duration_p_to: 0
    };

    let current_filters = default_filters;
    if (
      localStorage.getItem("filters") !== null &&
      localStorage.getItem("filters") !== "null"
    ) {
      current_filters = JSON.parse(localStorage.getItem("filters"));
    }

    this.state = {
      default_filters: default_filters,
      filters: current_filters,
      recordsNumber: 0,
      pageNumber: 0,
      idDatasest: this.props.match.params.id,
      recordsArray: [],
      user: JSON.parse(localStorage.getItem("user"))
    };
    this.get_records = this.get_records.bind(this);
    this.reset_filters = this.reset_filters.bind(this);
    this.apply_filters = this.apply_filters.bind(this);
    this.handleTextFieldChange = this.handleTextFieldChange.bind(this);
    this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
    this.open_record = this.open_record.bind(this);
  }

  componentDidMount() {
    this.get_records();
  }

  async get_records() {
    let response = await APIrequest.get_records_list(
      this.state.user,
      this.state.idDatasest,
      this.state.pageNumber,
      this.state.filters
    );
    this.setState({
      recordsArray: response.data.records,
      pageNumber: this.state.pageNumber + 1,
      recordsNumber: response.data.records.length
    });

    this.refs.recordsCounter.innerText =
      "Records list(" + response.data.records.length + ")";
  }

  handleOnDocumentBottom = () => {
    this.get_records();
  };

  handleTextFieldChange(e, newName) {
    var filters = { ...this.state.filters };
    filters[newName] = e.target.value;
    this.setState({ filters });
  }

  handleCheckBoxChange(e, newName) {
    var filters = { ...this.state.filters };
    filters[newName] = e.target.checked;
    this.setState({ filters });
  }

  handleDateChange(date, newName) {
    var filters = { ...this.state.filters };
    filters[newName] = new Date(date);
    this.setState({ filters });
  }

  apply_filters() {
    this.get_records();
    localStorage.setItem("filters", JSON.stringify(this.state.filters));
  }

  reset_filters() {
    localStorage.setItem("filters", JSON.stringify(this.state.default_filters));
    this.setState(
      {
        recordsArray: [],
        pageNumber: 0,
        filters: this.state.default_filters
      },
      this.get_records
    );

  }

  open_record(record) {
    console.log(record);
    this.props.history.push({
      pathname: "/record/" + record.id,
      state: record
    });
  }

  render_record(a) {
    let contributorClass =
      a.current_contributor_id == null
        ? "list-item-container-default"
        : "list-item-container-locked";
    let annotationClass = a.is_annotated
      ? "list-item-container-annotated"
      : "list-item-container-default";

    let item = (
      <div className="record-item-container">
        <ListItem key={a.id} className="" onClick={e => this.open_record(a)}>
          <div className="record-data">
            <div className="record-metadata">
              <span className="record-value">ID : {a.id}</span>
              <br />
              <span className="record-value">
                Created at : {new Date(a.date_creation).toLocaleDateString()}
              </span>
              <br />
              <span className={contributorClass}>
                Locked by : {a.annotated_by}
              </span>
              <br />
              <span class={annotationClass}>
                Is annotated : {a.is_annotated.toString()}
              </span>
            </div>
            <div className="record-characteristics">
              <span className="record-value">Type : {a.record_type}</span>
              <br />
              <span className="record-value">Length : {a.ecg_length}</span>
              <br />
              <span className="record-value">
                Length(time) : {a.ecg_duration}s
              </span>
              <br />
              <span className="record-value">Frequency : {a.frequency}Hz</span>
              <br />
            </div>
            <div className="record-additional">
              <span className="record-value">Comment : {a.comment}</span>
              <br />
            </div>
          </div>
        </ListItem>
      </div>
    );

    return item;
  }

  render() {
    let listHeader = null;
    listHeader = (
      <div className="records-list-header">
        <span ref="recordsCounter">
          Records list({this.state.recordsNumber})
        </span>
      </div>
    );

    let filters = (
      <div records-filter-container>
        <div className="records-list-filters">
          <div className="filter-metadata">
            <TextField
              onChange={e => this.handleTextFieldChange(e, "record_type")}
              label="Record type"
              type="text"
              value={this.state.filters.record_type}
            />
            <br />
            <TextField
              onChange={e => this.handleTextFieldChange(e, "frequency")}
              label="Frequencу"
              type="Number"
              value={this.state.filters.frequency}
            />
            <br />
            <TextField
              label="Annotated by"
              type="text"
              value={this.state.filters.annotated_by}
              onChange={e => this.handleTextFieldChange(e, "annotated_by")}
            />
            <br />
            <FormControlLabel
              value="Blocked"
              control={
                <Checkbox
                  color="primary"
                  checked={this.state.filters.is_blocked}
                  onChange={e => this.handleCheckBoxChange(e, "is_blocked")}
                />
              }
              label="Blocked"
            />
            <br />
            <FormControlLabel
              value="Annotated"
              control={
                <Checkbox
                  color="primary"
                  checked={this.state.filters.is_annotated}
                  onChange={e => this.handleCheckBoxChange(e, "is_annotated")}
                />
              }
              label="Annotated"
            />
          </div>
          <div className="filter-characteristics">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                margin="normal"
                id="date-picker-dialog"
                label="From date"
                format="dd/MM/yyyy"
                value={this.state.filters.from_date}
                onChange={date => this.handleDateChange(date, "from_date")}
                KeyboardButtonProps={{
                  "aria-label": "change date"
                }}
              />
              &ensp;&ensp;
              <KeyboardDatePicker
                margin="normal"
                id="date-picker-dialog"
                label="To date"
                format="dd/MM/yyyy"
                onChange={date => this.handleDateChange(date, "to_date")}
                value={this.state.filters.to_date}
                KeyboardButtonProps={{
                  "aria-label": "change date"
                }}
              />
            </MuiPickersUtilsProvider>
            <FormGroup row>
              <TextField
                label="Duration from(number)"
                type="Number"
                value={this.state.filters.duration_p_from}
                onChange={e => this.handleTextFieldChange(e, "duration_p_from")}
              />
              &ensp;&ensp;
              <TextField
                label="Duration to(number)"
                type="Number"
                value={this.state.filters.duration_p_to}
                onChange={e => this.handleTextFieldChange(e, "duration_p_to")}
              />
            </FormGroup>
            <FormGroup row>
              <TextField
                label="Duration from(seconds)"
                type="Number"
                value={this.state.filters.duration_s_from}
                onChange={e => this.handleTextFieldChange(e, "duration_s_from")}
              />
              &ensp;&ensp;
              <TextField
                label="Duration to(seconds)"
                type="Number"
                value={this.state.filters.duration_s_to}
                onChange={e => this.handleTextFieldChange(e, "duration_s_to")}
              />
            </FormGroup>
          </div>
        </div>
        <div className="records-filter-buttons">
          <Button size="large" color="primary" onClick={this.apply_filters}>
            Apply
          </Button>
          <Button size="large" color="secondary" onClick={this.reset_filters}>
            Reset
          </Button>
        </div>
        <Divider />
      </div>
    );
    return (
      <div className="records-container">
        {listHeader}
        <Divider />
        <br />
        {filters}
        <List className="records-list">
          {this.state.recordsArray.map(a => this.render_record(a))}
        </List>
        <BottomScrollListener onBottom={this.handleOnDocumentBottom} />
      </div>
    );
  }
}

export default withRouter(RecordsList);
