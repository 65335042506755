import { List, ListItem } from "material-ui/List";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import logo from "../../img/mawi_logotype_full_w.svg";
import return_button from "../../img/left-arrow.svg";
import logout from "../../img/logout.svg";
import Plot from "react-plotly.js";
import { withRouter } from "react-router-dom";
import "./css/record.css";
import ECGChart from "./../ECGChart/Chart";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import APIrequest from "../../APICalls/api";
import { annToInd, indToAnn, filterRange } from "../../Utils";
class Record extends React.Component {
  constructor(props) {
    super(props);
    this.childOne = React.createRef();

    this.state = {
      index: this.props.location.state.current_batch_number,
      maxIndex: this.props.location.state.max_batch_number - 1,
      offset: this.props.location.state.frequency * 4,
      ecg: Array(this.props.location.state.max_batch_number).fill(null),
      ann_def_x: Array(this.props.location.state.max_batch_number).fill(null),
      ann_new_x: Array(this.props.location.state.max_batch_number).fill(null),

      recordId: this.props.location.state.id,
      date_creation: this.props.location.state.date_creation,
      frequency: this.props.location.state.frequency,
      record_type: this.props.location.state.record_type,
      user: JSON.parse(localStorage.getItem("user"))
    };
  }

  componentDidMount() {
    this.setDataInitial();
  }

  async setDataInitial() {
    const {
      user,
      recordId,
      ecg,
      ann_def_x,
      ann_new_x,
      index,
      offset
    } = this.state;

    let response = await APIrequest.get_points(
      user,
      recordId,
      index,
      offset,
      true
    );

    let json = response.data.data;
    ecg[index] = json.signal_raw;

    for (let i = 0; i < ann_def_x.length; i++) {
      ann_def_x[i] = filterRange(json.default_annotation, offset, i);
      ann_new_x[i] = filterRange(json.manual_annotation, offset, i);
    }

    let chart_ann_def = indToAnn(ann_def_x[index], offset, index);
    let chart_ann_new = indToAnn(ann_new_x[index], offset, index);

    this.childOne.setPoints(ecg[index], chart_ann_def, chart_ann_new);
  }

  async setDataFromAPI() {
    const {
      user,
      recordId,
      ecg,
      ann_def_x,
      ann_new_x,
      index,
      offset
    } = this.state;

    let response = await APIrequest.get_points(
      user,
      recordId,
      index,
      offset,
      false
    );

    let json = response.data.data;
    ecg[index] = json.signal_raw;

    let chart_ann_def = indToAnn(ann_def_x[index], offset, index);
    let chart_ann_new = indToAnn(ann_new_x[index], offset, index);

    this.childOne.setPoints(ecg[index], chart_ann_def, chart_ann_new);
  }

  async setDataFromMemory() {
    const {
      user,
      recordId,
      ecg,
      ann_def_x,
      ann_new_x,
      index,
      offset
    } = this.state;

    let chart_ann_def = indToAnn(ann_def_x[index], offset, index);
    let chart_ann_new = indToAnn(ann_new_x[index], offset, index);

    this.childOne.setPoints(ecg[index], chart_ann_def, chart_ann_new);
  }

  async nextPart() {
    const { index, maxIndex, ecg, ann_def_x, ann_new_x, offset } = this.state;

    console.log(index);
    if (index + 1 > maxIndex) {
      console.log(maxIndex);
      return;
    }

    let chState = this.childOne.state;

    let old_points = annToInd(chState.ann_def.x, offset, index);
    ann_new_x[index] = annToInd(chState.ann_new.x, offset, index);

    ann_new_x[index] = ann_new_x[index].concat(old_points);

    if (ecg[index] == null) {
      this.setState({ index: index + 1 }, this.setDataFromMemory);
    } else {
      this.setState({ index: index + 1 }, this.setDataFromAPI);
    }
  }

  previousPart() {
    const {
      index,
      max_batch_number,
      ecg,
      ann_def_x,
      ann_new_x,
      offset
    } = this.state;

    if (index - 1 < 0) {
      return;
    }

    let chState = this.childOne.state;

    let old_points = annToInd(chState.ann_def.x, offset, index);
    ann_new_x[index] = annToInd(chState.ann_new.x, offset, index);
    ann_new_x[index] = ann_new_x[index].concat(old_points);

    if (ecg[index] == null) {
      this.setState({ index: index - 1 }, this.setDataFromMemory);
    } else {
      this.setState({ index: index - 1 }, this.setDataFromAPI);
    }
  }

  async saveRecord() {
    const { ecg, ann_def_x, ann_new_x, index, offset } = this.state;

    let chState = this.childOne.state;

    let old_points = annToInd(chState.ann_def.x, offset, index);
    ann_new_x[index] = annToInd(chState.ann_new.x, offset, index);

    ann_new_x[index] = ann_new_x[index].concat(old_points);

    let result = [];
    for (let i = 0; i < ann_new_x.length; i++) {
      if (ann_new_x[i] == null) {
        result = result.concat(ann_def_x[i]);
      } else {
        result = result.concat(ann_new_x[i]);
      }
    }

    result = result.sort((a, b) => a - b);
    let response = await APIrequest.save_annotation(
      this.state.user,
      this.state.recordId,
      result,
      this.state.index
    );
    this.props.history.goBack();
  }

  return(event) {
    this.props.history.goBack();
  }

  render() {
    let range_current = this.state.index+1 ;
    let range_max = this.state.maxIndex+1;
    let percent =  (100 * this.state.index / this.state.maxIndex).toFixed(2);
    return (
      <div className="App">
        <div className="App-header">
          <div className="App-header-content">
            <div className="App-header-left">
              <Link
                className="container-header-button"
                onClick={this.return.bind(this)}
              >
                Return to records
              </Link>
            </div>

            <div className="App-header-right">
              <div className="App-header-right-seperator" />
              <div className="App-header-logout-div">
                <Link
                  className="logout-Link"
                  to="/login"
                  onClick={this.handleLogout}
                >
                  <img
                    src={logout}
                    className="App-header-logout-img"
                    alt="logout"
                  />
                  <p className="App-header-logout-txt">Logout</p>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="App-content">
          <div className="container">
            <div className="container-header">
              <p className="container-header-date">
                Date : {this.state.date_creation}
              </p>
              <p className="container-header-frequency">
                Frequency : {this.state.frequency}
              </p>
              <p className="container-header-frequency">
                Record type : {this.state.record_type}
              </p>
            </div>
            <br/>
            <div className="container-header-current">
              <p className="container-header-current-value">{range_current} / {range_max} ({percent}%)
            </p>
            </div>
            <div className="container-chart">
              <ECGChart
                onSave={this.onParentSave}
                height={window.innerHeight}
                width={window.innerWidth}
                ecg={this.state.ecg}
                annotation_x={this.state.annotation_x}
                ref={node => (this.childOne = node)}
              />
            </div>
            <div className="container-buttons">
              <div className="container-buttons-button-container">
                <Button
                  className="container-buttons-save"
                  color="primary"
                  onClick={this.previousPart.bind(this)}
                >
                  Left
                </Button>
              </div>
              <div className="container-buttons-button-container">
                <Button
                  className="container-buttons-save"
                  color="secondary"
                  size="large"
                  onClick={this.saveRecord.bind(this)}
                >
                  Save
                </Button>
              </div>
              <div className="container-buttons-button-container">
                <Button
                  className="container-header-right"
                  color="primary"
                  onClick={this.nextPart.bind(this)}
                >
                  Right
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Record);
