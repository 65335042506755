import React from 'react';
import './css/listStyles.css';
import {
  List,
  ListItem
} from 'material-ui/List';
import Divider from 'material-ui/Divider';
import APIrequest from '../../APICalls/api';
import { withRouter } from "react-router-dom";
import { Link } from 'react-router-dom'
import Moment from 'moment';
import ReactHtmlParser from 'react-html-parser';
class DatasetsList extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
        datasetsArray: [],
        user: JSON.parse(localStorage.getItem('user'))
      }

      this.get_datasets = this.get_datasets.bind(this);

  }

  componentDidMount() {
        this.get_datasets();
    }

  async get_datasets() {
    let response = await APIrequest.get_datasets_list(this.state.user)
    this.setState({ datasetsArray: response.data.datasets});
    }


  render() {
    console.log(this.state);
    let listItem = null;
    let listItems = [];
    let listHeader = null;

    this.state.datasetsArray.map(a => {
      listItem =
        <div key={a.id} className="list-item-container">
          <Divider />
            <ListItem className="list-item" button component="a" href={'datasets/'+a.id}>
              <div className="list-item-data" >
                <div className="list-item-data-metadata">
                  <span className="list-item-id">ID : {a.id}</span>
                  <br/>
                  <span className="list-item-author"><strong>Creator : </strong>{a.creator}</span>
                  <br/>
                  <span className="list-item-date-creation">Created at : {(new Date(a.date_creation)).toLocaleDateString()}</span>
                </div>
                <div className="list-item-data-description">
                  <span className="list-item-records-number">Total records : {a.records_number}</span>
                  <br/>
                  <span className="list-item-description">{ ReactHtmlParser (a.description) }</span>
                </div>
              </div>
            </ListItem>
            <Divider />
        </div>

      listItems.push(listItem)
    });

    listHeader =
      <div className="list-header">
        <span>Datasets list</span>
      </div>


    return (
      <div>
        {listHeader}
        <List className="list-grid">
          {listItems}
        </List>
      </div>
    )
  }
};
export default withRouter(DatasetsList)
