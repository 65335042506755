import { createStore,  applyMiddleware } from 'redux'
import rootReducer from '../reducers'
// import logger from 'redux-logger'
// import {myServiceMiddleware} from "../components/Calendar/Modal";
// import { composeWithDevTools } from 'redux-devtools-extension';
// import { persistState } from 'redux-devtools';

// export let isMonitorAction;

// const serviceMiddleware = myServiceMiddleware;
// const store = createStore(reducer, applyMiddleware(serviceMiddleware))

// const enhancers = compose(
// 	window.devToolsExtension ? window.devToolsExtension() : f => f
// );
/* eslint-disable no-underscore-dangle */
export default function configureStore(initialState) {
  const store = createStore(rootReducer, initialState, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(), applyMiddleware()) //, applyMiddleware(serviceMiddleware)

  if (module.hot) {
    module.hot.accept('../reducers', () => {
      const nextRootReducer = require('../reducers')
      store.replaceReducer(nextRootReducer)
    })
  }

  return store
}
/* eslint-enable */
