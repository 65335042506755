import React from 'react';
import ReactDOM from 'react-dom';
import registerServiceWorker from './registerServiceWorker';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
// import injectTapEventPlugin from 'react-tap-event-plugin';
// import { createMuiTheme } from '@material-ui/core/styles';
// import getMuiTheme from 'material-ui/styles/getMuiTheme';

import Root from './root';
import { BrowserRouter } from 'react-router-dom';

import { Provider } from 'react-redux';
// import { createStore } from 'redux';
import configureStore from './store/configureStore';

export const store = configureStore();

ReactDOM.render(
	<Provider store={store}>
		<MuiThemeProvider>
			<BrowserRouter>
	    		<Root />
	    	</BrowserRouter>
	    </MuiThemeProvider>
	</Provider>,
  	document.getElementById('root')
);

registerServiceWorker();
