// Chart configuration
var layout_config = {
  //  margin : {'t': 0, 'l' : 0, 'r' : 0, 'b' : 0},
  hovermode: "closest",
  showlegend: false,
  datarevision: 0,
  xaxis: {
    showline: false,
    zeroline: false,
    showticklabels: false,
    range: [0, 0]
  },
  yaxis: {
    showline: false,
    zeroline: false,
    showticklabels: false,
    range: [0, 0]
  },
  responsive: true
};

var chart_config = {
  scrollZoom: true,
  displayModeBar: false
};

// Data configuration

var ecg_line = {
  y: [],
  type: "scatter",
  mode: "lines",
  hoverinfo: "none",
  line: {
    color: "blue",
    size: 0.01
  }
};

var annotation_default_points = {
  x: [],
  y: [],
  type: "scatter",
  mode: "markers",
  hoverinfo: "none",
  marker: {
    color: "red",
    size: 10
  }
};

var annotation_new_points = {
  x: [],
  y: [],
  type: "scatter",
  mode: "markers",
  hoverinfo: "none",
  marker: {
    colors: ["green"],
    size: 10
  }
};

var hovered_points = {
  x: [],
  y: [],
  hoverinfo: "none",
  type: "scatter",
  mode: "markers",
  hoverinfo: "none",
  marker: {
    color: "orange",
    size: 10
  }
};

export {
  layout_config,
  chart_config,
  ecg_line,
  annotation_default_points,
  annotation_new_points,
  hovered_points
};
