import React, {
  Component
} from 'react';
import {
  Link
} from 'react-router-dom';
import logo from './img/mawi_logotype_full_w.svg';
import logout from './img/logout.svg';

class App extends Component {

  constructor(props) {
    super(props);
    this.handleLogout = this.handleLogout.bind(this);
    this.redirectUserToPage = this.redirectUserToPage.bind(this);
    this.currentUserName = '';
    this.currentUserID = null;
  }

  componentDidMount() {
    this.redirectUserToPage();
  }

  redirectUserToPage() {
    if (localStorage.getItem('user') === null || localStorage.getItem('user') === 'null') {
      this.props.history.push('/login')
      console.log("no user");
    } else {
      this.props.history.push('/datasets')
    }
  }

  handleLogout() {
    localStorage.setItem('user', JSON.stringify(null));
    this.props.history.push('/login')
  }

  render() {
    // let currentUser = JSON.parse(localStorage.getItem('user'));
    // if (currentUser && currentUser.name) {
    //   this.currentUserName = currentUser.access;
    //   this.currentUserID = currentUser.controller_id;
    // }


    return (
      <div className="App">
        <div className="App-header">
          <div className="App-header-content">
            <div className="App-header-left">
              <Link to="/">
                <img src={logo} className="App-logo" alt="logo" />
              </Link>
            </div>

            <div className="App-header-right">

              <div className="App-header-right-seperator"/>
              <div className="App-header-logout-div">
                <Link className="logout-Link" to="/login" onClick={this.handleLogout}>
                  <img src={logout} className="App-header-logout-img" alt="logout" />
                  <p className="App-header-logout-txt">Logout</p>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="App-content">
        </div>
      </div>
    );
  }
}

export default App;
