import { combineReducers } from 'redux';
import usersID from './usersID';
import modalState from "./modal";
import doctorInfo from "./doctorInfo";
import rangeSliderEvent from "./rangeSliderEvent";

export default combineReducers({
  usersID,
  modalState,
  doctorInfo,
  rangeSliderEvent
})
